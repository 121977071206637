<template>
  <section class="cupcake-sku-choice">
    <framed-image :navigate-to="skuImage" :images="productImages" />
    <div class="mx-2">
      <h2 class="a-h">How many would you like?</h2>
      <div class="buttons flex-wrap">
        <standard-button 
          v-for="item in skus" :key="item.id"
          @click="setSku(item)"
          :active="value == item"
        >
          <span class="size">{{item.cupcake_slots}}</span> <em class="lowercase"> for </em>
          <span class="price">{{item.price | tightCurrency }}</span>
        </standard-button>
      </div>
    </div>
  </section>
</template>


<script>
import FramedImage from '@components/FramedImage.vue'
import StandardButton from '@components/StandardButton.vue'
import { first, map, findIndex, compact } from 'lodash'
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    skus: {},
  },
  components: {
    FramedImage,
    StandardButton
  },
  methods: {
    setSku(id) {
      this.$emit('input',id)
      // this.$scrollTo(document.getElementById('flavours'), 1000, { offset: -50 })
    }
  },
  computed: {
    productImages() {
      return compact(map(this.skus, 'image_url'))
    },
    skuImage() {
      return findIndex(this.skus, this.value)
    },
  }
}
</script>
