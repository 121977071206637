<template>
  <p class="text-gray-700 text-sm mb-2 a-h">You've chosen a {{sku.full_name}}
     for {{sku.price | tightCurrency }}
    <template v-if="chosenFlavours.length">
      with {{ flavourNames.join(", ")}}
    </template>
    <template v-if="topper">
      with a Thank You Topper
    </template>
    <template v-if="bacon">
      without Maple Bacon
    </template>
  </p>
</template>

<script>
import { find, filter, map } from 'lodash'
export default {
  props: ['sku', 'chosenFlavours','topper', 'bacon'],
  computed: {
    flavourNames() {
      return map(this.chosenFlavours, 'name')
    }
  },
}
</script>

<style>

</style>