<template>
  <p class="text-gray-700 text-sm mb-2">You've chosen a {{sku.full_name}}<template v-if="chosenFlavour">, {{ chosenFlavour.name}}</template>
     for {{sku.price | tightCurrency }}
    <template v-if="colour && colour.colour_type == 'other' && customColours && customColours.length > 0">
      in {{ customColours.join(", ") }}
    </template>
    <template v-else-if="colour && customColours && customColours.length > 0">
      in {{colour.name}} and {{ customColours.join(", ") }}
    </template>
    <template v-else-if="colour">
      in {{ colour.name }}
    </template>
    <template v-else-if="customColours && customColours.length > 0">
      in {{ customColours.join(", ") }}
    </template>


    <template v-if="group && group.has_message">
      <template v-if="message">
        with a message: "{{message}}"
      </template>
      <template v-else>
        with <strong>no message</strong>
      </template>
    </template>
  </p>
</template>

<script>
export default {
  props: ['sku', 'chosenFlavour','message','colour', 'customColours','group']
}
</script>
