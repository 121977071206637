<template>
  <section class="cupcake-flavour-picker">
    <div class="mx-4 sm:mx-2 am-0">
      <h2 class="a-h">Choose up to {{slots}} flavours</h2>
      <div class="flavours sm:flex">
        <standard-button 
          v-for="item in flavours" :key="item.id"
          @click="setFlavour(item)"
          :active="value.includes(item)"
          :disabled="disabled && !value.includes(item)"
        >
          {{item.name}}
        </standard-button>
      </div>
    </div>
  </section>
</template>


<script>
import StandardButton from '@components/FlavourButton.vue'
import { filter } from 'lodash'
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    group: {},
    sku: {},
    flavours: []
  },
  components: {
    StandardButton
  },
  methods: {
    setFlavour(id) {
      if(this.value.includes(id)) {
        this.$emit('input', filter(this.value, o => o != id))
      } else {
        if(!this.disabled) {
          this.$emit('input', this.value.concat(id))
        }
      }
    }
  },
  computed: {
    slots() {
      return Math.min(6,this.sku.cupcake_slots)
    },
    disabled() {
      return this.value.length >= this.slots
    }
  }
}
</script>
