<template>
  <div>
    <div class="a-w">
      <cupcake-size-choice v-model="size" :sizes="sizes" :image="group.size_image_url" />
      <div id="box"></div>
      <cupcake-sku-choice v-if="size" v-model="sku" :skus="filteredSkus" />

      <template v-if="group.flavour_picker">
        <div id="flavours"></div>
        <FlavourChoice v-model="letMeChoose" v-if="size && sku" :sku="sku" />
        <div id="picker"></div>
        <div class="a-b">
          <template v-if="size && sku && letMeChoose">
            <FlavourPicker v-model="chosenFlavours" :sku="sku" :flavours="flavours" />
            <Box :sku="sku" :flavours="chosenFlavours" />
          </template>
        </div>
      </template>
    </div>

  <!--
    <div class="mb-8" v-if="flavourPicker && size && sku && false">
      <label class="text-sm text-left mr-2">
        <input type="checkbox" v-model="topper">
        Include a Thank You Topper
      </label>
    </div>

    <div class="mb-8" v-if="flavourPicker && size && sku && false">
      <label class="text-sm text-left mr-2">
        <input type="checkbox" v-model="bacon">
        No Maple Bacon please!
      </label>
    </div>
  -->

  <div v-if="size && sku" class="px-4">
    <item-summary :sku="sku" :chosenFlavours="chosenFlavours" :topper="topper" :bacon="bacon" />
    <form :action="url" method="post">
      <input type="hidden" name="id" v-model="id" v-if="id" />
      <input type="hidden" name="sku" :value="sku.id" v-if="sku" />
      <input type="hidden" name="flavours" :value="flavourIds" />
      <input type="hidden" name="topper" v-model="topper" />
      <input type="hidden" name="bacon" v-model="bacon" />
      <button type="submit"
        class="bg-jblue py-4 px-4 block w-full uppercase font-proxima text-white">
        {{ id ? "Update order" : `Add to basket` }}
      </button>
    </form>
  </div>
  </div>
</template>

<script>

import { uniq, find, filter } from 'lodash'
import FlavourChoice from '@components/FlavourChoice.vue';
import FlavourPicker from '@components/FlavourPicker.vue';
import Box from '@components/Box.vue';
import ItemSummary from '@components/ItemSummary.vue';
import CupcakeSizeChoice from '@components/CupcakeSizeChoice.vue';
import CupcakeSkuChoice from '@components/CupcakeSkuChoice.vue';

export default {
  props: {
    value: {}, 
    group: {},
    url: {
      default: '/order/add',
      type: String,
    },
    skus: {
      default: () => ([]),
      type: Array,
    },
    flavours: {
      default: () => ([]),
      type: Array,
    },
  },
  data() {
    return {
      sku: null,
      id: null,
      chosenFlavours: [],
      topper: false,
      bacon: false,
      size: this.value ? this.value.size : null,
      letMeChoose: this.value ? this.value.flavour_ids.length > 0 : false,
    }
  },
  mounted() {
    if(this.value) {
      this.sku = find(this.skus, (sku) => sku.id == this.value.sku_id)
      this.chosenFlavours = filter(this.flavours, (flavour) => this.value.flavour_ids.includes(flavour.id))
      this.bacon = this.value.bacon
      this.topper = this.value.topper
      this.id = this.value.id
    }
  },  
  components: {
    FlavourChoice,
    FlavourPicker,
    Box,
    ItemSummary,
    CupcakeSizeChoice,
    CupcakeSkuChoice
  },
  computed: {

    flavourIds() {
      return this.chosenFlavours.map((flavour) => flavour.id)
    },

    sizes() {
      return uniq(this.skus.map((sku) => sku.cupcake_size))
    },

    filteredSkus() {
      return this.skus.filter((sku) => sku.cupcake_size == this.size)
    },
  },
  watch: {
    size() {
      this.sku = null
    },
    letMeChoose() {
      if(!this.letMeChoose) {
        this.chosenFlavours = []
      }
    }
  }
}
</script>
