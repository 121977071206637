<template>
  <section>
    <div class="mx-2" v-if="group.slug == 'splatter' || group.slug == 'marble'">
      <h2>What colours would you like?</h2>
      <p class="text-sm text-gray-600 mb-2">You can choose up to {{ group.colour_choices }}</p>
      <input type="text" v-for="i in group.colour_choices" :key="i" class="input text-center mb-1" maxlength="15" :placeholder="`Colour choice ${i}`" v-model="choices[i-1]" />
    </div>

    <div class="mx-2" v-else-if="group.slug == 'drip'">
      <h2>What colour of drip would you like?</h2>
      <p class="text-sm text-gray-600 mb-2">We will match the top accordingly</p>
      <div class="buttons flex-wrap">
        <standard-button 
          v-for="item in group.colours" :key="item.id"
          @click="setColour(item)"
          :active="value == item"
        >
          {{ item.name }}
        </standard-button>
      </div>
      <div class="px-2" v-if="value && value.colour_type == 'other'">
        <input type="text" class="input text-center mb-1" maxlength="15" placeholder="Other" v-model="choices[0]" />
      </div>
    </div>


    <div class="mx-2" v-else-if="group.slug === 'abstract'">
      <h2>What colour would you like?</h2>
      <input type="text" v-for="i in group.colour_choices" :key="i" class="input text-center mb-1" maxlength="15" :placeholder="`Colour choice ${i}`" v-model="choices[i-1]" />
      <h2 class="mt-4">What colour of gold leaf would you like?</h2>
      <div class="buttons flex-wrap">
        <standard-button 
          v-for="item in group.colours" :key="item.id"
          @click="setColour(item)"
          :active="value == item"
        >
          {{ item.name }}
        </standard-button>
      </div>
    </div>
    <div class="mx-2" v-else>
      <h2>What colour would you like?</h2>
      <div class="buttons flex-wrap">
        <standard-button 
          v-for="item in group.colours" :key="item.id"
          @click="setColour(item)"
          :active="value == item"
        >
          {{ item.name }}
        </standard-button>
      </div>
    </div>
  </section>
</template>


<script>
import StandardButton from '@components/StandardButton.vue'
import { compact, map } from 'lodash'
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    group: {},
    customColours: {}
  },
  components: {
    StandardButton
  },
  data() {
    return {
      choices: this.customColours ? compact(this.customColours) : [],
    }
  },
  watch: {
    choices() {
      this.$emit('choices', this.choices)
    }
  },
  methods: {
    setColour(id) {
      this.$emit('input',id)
      this.$scrollTo(document.getElementById('flavours'), 1000, { offset: -50 })
    }
  }
}
</script>
