<template>
  <div class="wrapper m-2 flex-1">
    <button 
      @click="$emit('click')"
      class="
        block 
        w-full
        border border-solid border-jpink
        px-4 py-2 
        text-jpink font-proxima 
        hover:bg-jpink
        hover:bg-opacity-25
        transition duration-500 ease-in-out
        focus:outline-none
        uppercase"
      :class="{
        'bg-jpink bg-opacity-25': active,
        'border-gray opacity-25': disabled
      }">
      <slot></slot>
    </button>
    <div class="mt-1 text-gray-600 text-sm a-h" v-if="!!$slots.hint">
      <slot name="hint"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
  .wrapper {
    max-width: 50%;
  }
  p {
    @apply text-sm;
    @apply mb-4;
  }
</style>