<template>
  <div>
    <CakeSkuChoice :skus="skus" v-model="sku" :image="group.size_image_url" />
    <div id="colours">
      <CakeColourPicker v-model="colour" :group="group" v-if="showColour" @choices="setChoices" :customColours="customColours" />
    </div>


    <div id="flavours"></div>
    <CakeFlavourPicker v-model="flavour" :sku="sku" :flavours="group.flavours" v-if="showFlavours" />

    <div id="message"></div>
    <section class="px-4" v-if="showMessage">
      <h2 class="mb-0">Would you like a message or number on the cake?</h2>
      <template v-if="group.message_image_url">
      <a href="#" class="text-jblue text-sm mb-4 block" @click.prevent="toggler = !toggler">Show me an example</a>
      <FsLightbox
        :toggler="toggler"
        :sources="[group.message_image_url]"
        /></template>
      <input type="text" class="input text-center" maxlength="40" placeholder="Enter your message or number here" v-model="message" />
    </section>

    <section class="px-4" v-if="showTopperWarning">
      <h2>Toppers</h2>
      <p class="text-sm">Toppers shown aren't included in the price, but can be easily added. Prices start from £5. Let us know what you'd like in the "Additional Comments" box during checkout.</p>
    </section>
    <section class="px-4" v-if="showTopperIncluded">
      <h2>Toppers</h2>
      <p class="text-sm">Toppers shown are included in the price. Yay!</p>
    </section>

    <div v-if="showSummary" class="px-4">
      <cake-item-summary :sku="sku" :group="group" :chosenFlavour="flavour" :message="message" :colour="colour" :customColours="customColours" />
      <form action="/order/add" method="post">
        <input type="hidden" name="id" v-model="id" v-if="id" />
        <input type="hidden" name="sku" :value="sku && sku.id" />
        <input type="hidden" name="colour" :value="colour && colour.id" />
        <input type="hidden" name="custom_colours" :value="customColours && customColours.join('|')" />
        <input type="hidden" name="flavours" :value="flavour && flavour.id" />
        <input type="hidden" name="message" :value="message" />
        <button type="submit"
          class="bg-jblue py-4 px-4 block w-full uppercase font-proxima text-white">
          {{ id ? "Update order" : `Add to basket` }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>

import { find } from 'lodash'

import FsLightbox from "fslightbox-vue/v2";
import CakeSkuChoice from '@components/CakeSkuChoice.vue';
import CakeFlavourPicker from '@components/CakeFlavourPicker.vue';
import CakeColourPicker from '@components/CakeColourPicker.vue';
import CakeItemSummary from '@components/CakeItemSummary.vue';

export default {
  props: {
    value: {}, 
    group: {},
    skus: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      sku: null,
      id: null,
      message: null,
      flavour: null,
      colour: null,
      customColours: null,
      toggler: false
    }
  },
  mounted() {
    if(this.value) {
      this.sku = find(this.skus, (item) => item.id == this.value.sku_id)
      this.colour = find(this.group.colours, (item) => item.id == this.value.colour_id)
      this.flavour = find(this.group.flavours, (item) => item.id == this.value.flavour_id)
      this.message = this.value.message
      this.customColours = this.value.custom_colours
      this.id = this.value.id
    }

  },
  components: {
    CakeSkuChoice,
    CakeFlavourPicker,
    CakeItemSummary,
    CakeColourPicker,
    FsLightbox
  },
  methods: {
    setChoices(e) {
      this.customColours = e
    }
  },
  computed: {
    hasColours() {
      return this.group.colour_picker || this.group.colour_choices > 0
    },
    showColour() {
      return this.sku && this.hasColours
    },
    showTopperWarning() {
      return this.group && this.group.topper_type == "topper_extra"
    },
    showTopperIncluded() {
      return this.group && this.group.topper_type == "topper_included"
    },
    showFlavours() {
      if(this.hasColours) {
        return this.sku && this.group && (this.colour || this.customColours)
      } else {
        return this.sku && this.group
      }
    },
    showMessage() {
      return this.showFlavours && this.flavour && this.group.has_message
    },
    showSummary() {
      return this.showFlavours && this.flavour
    }
  }
}
</script>
