<template>
  <div class="relative mb-4 a-h">
    <div class="z-10">
      <template v-if="images.length > 1">
        <div class="glide" ref="glide">
          <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">
              <li class="glide__slide" v-for="(image,i) in filteredImages" :key="`slide-${i}`">
                <img :src="image" />
              </li>
            </ul>
          </div>
          <div class="glide__bullets" data-glide-el="controls[nav]">
            <button class="glide__bullet" :data-glide-dir="`=${i}`" v-for="(image, i) in filteredImages" :key="`button-${i}`"></button>
          </div>
        </div>
      </template>
      <template v-else-if="images.length == 1">
        <img :src="image" />
      </template>
    </div>

    <template v-if="images.length > 0">
      <div v-if="frameStyle === 'standard'" class="z-0 border border-white border-solid m-4 absolute frame top-0 left-0 pointer-events-none"></div>
      <div v-else class="z-0 border border-white border-solid m-2 absolute frame-small top-0 left-0 pointer-events-none"></div>
    </template>
  </div>
</template>

<script>
import Glide from '@glidejs/glide'
import { uniq, last } from 'lodash'
export default {
  props: {
    images: {},
    navigateTo: {},
    height: {},
    frameStyle: {
      type: String,
      default: "standard"
    },
    width: {
      type: String,
      default: "500"
    },
    autoplay: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      glide: null
    }
  },
  mounted() {
    this.glide = new Glide(this.$refs.glide).mount()
  },
  watch: {
    navigateTo() {
      this.glide.go(`=${this.navigateTo}`)
    }
  },
  computed: {
    filteredImages() {
      return uniq(this.images)
    }
  }
}
</script>

<style>
.VueCarousel-pagination--bottom-overlay {
  bottom: 20px !important;
  z-index: 100;
  outline: none;
}
.VueCarousel-pagination--bottom-overlay:focus {
  outline: none;
}
</style>
