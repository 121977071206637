<template>
  <section>
    <div class="mx-4 sm:mx-2">
      <h2>{{name}}</h2>
      <div class="flavours sm:flex">
        <standard-button 
          v-for="item in flavours" :key="item.id"
          @click="setFlavour(item)"
          :active="value == item"
        >
          {{item.name}}
        </standard-button>
      </div>
      <p class="text-sm text-gray-700 mt-2" v-if="showAdditional">Don't see the flavour you are after?<br/>Add it to the "Any additional instructions" section during checkout</p>
      
    </div>
  </section>
</template>


<script>
import StandardButton from '@components/FlavourButton.vue'
import { filter, map, find } from 'lodash'
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    sku: {},
    flavours: {},
    name: {
      default: "What flavour would you like?"
    }
  },
  components: {
    StandardButton
  },
  methods: {
    setFlavour(id) {
      this.$emit('input', id)
      this.$scrollTo(document.getElementById('message'), 1000, { offset: -50 })
    }
  },
  computed: {
    showAdditional() {
      return !(this.flavours && this.flavours.length > 0)
    }
  }
}
</script>
