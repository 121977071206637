<template>
  <div>
    <span class="text-sm leading-tight" v-if="flavour == 'PICK'">Surprise me</span>
    <span class="text-lg leading-tight" v-else-if="flavour == 'EXTRA'">&#9676;</span>
    <span class="text-sm leading-tight" v-else>{{ flavour.name}}</span>
  </div>
</template>

<script>
export default {
  props: ['flavour']

}
</script>

<style>

</style>