<template>
  <section class="cupcake-flavour-choice">
    <div class="mx-2">
      <h2 class="a-h">What flavours would you like?</h2>
      <div class="buttons">
        
        <standard-button @click="setChoice(false)"
          :active="!value"
        >
          Surprise me
          <template v-slot:hint>
            We'll pick as many different flavours as we can
          </template>
        </standard-button>
        <standard-button @click="setChoice(true)"
          :active="value"
        >
          I'll choose
          <template v-slot:hint>
            You can choose up to {{slots}} different flavours
          </template>
        </standard-button>
      </div>
    </div>
  </section>
</template>

<script>
import StandardButton from '@components/StandardButton.vue'
import { find } from 'lodash'
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    sku: {}
  },
  components: {
    StandardButton
  },
  computed: {
    slots() {
      return Math.min(6,this.sku.cupcake_slots)
    }
  },
  methods: {
    setChoice(id) {
      this.$emit('input',id)
      this.$scrollTo(document.getElementById('picker'), 1000, { offset: -50 })  
    }
  }
}
</script>