<template>
  <section class="px-4 a-h">
    <h2>Your box</h2>
    <ul v-if="slots.length" class="
      flex justify-around flex-wrap align-center m-auto 
      mx-auto
      py-1 
      border border-grey border-solid"
      :style="{maxWidth: boxWidth}">
          <li 
            v-for="(slot,j) in slots" :key="`slot-${j}`"
            class="
            rounded-full w-24 h-24 overflow-hidden 
            bg-center bg-no-repeat bg-cover
            border-2 border-solid
            justify-center items-center flex
            m-1
            p-2
          ">
            <cupcake-slot :flavour="slot" />
          </li>
    </ul>

    <div v-if="hasExtra" class="text-sm mt-4 opacity-50">We'll pick {{ extraCount }} of your chosen flavours to fill up the box &uparrow;</div>
  </section>
</template>

<script>
import CupcakeSlot from '@components/CupcakeSlot.vue';
import { chunk, fill, flatten, sortBy } from 'lodash'
export default {
  props: {
    sku: {},
    flavours: {}
  },
  components: {
    CupcakeSlot
  },
  computed: {
    boxWidth() {
      if(this.slots.length == 4) {
        return "calc(7.5rem * 2)"
      } else if(this.slots.length == 6) {
        return "calc(7.5rem * 3)"
      } else {
        return "calc(7.5rem * 4)"
      }
    },
    chunks() {
      if(this.slots.length < 12) {
        return chunk(this.slots, this.slots.length / 2)
      } else if(this.slots.length < 24) { 
        return chunk(this.slots, this.slots.length / 3)
      } else {
        return chunk(this.slots, this.slots.length / 4)
      }
    },
    hasExtra() {
      return this.slots.includes("EXTRA")
    },  
    extraCount() {
      return this.slots.filter(o => o == "EXTRA").length
    },  
    slots() {
      if(!this.sku) {
        return []    
      }
      let out = Array(this.sku.cupcake_slots)
      if(this.flavours.length == 0) {
        return fill(out, "PICK")
      }

      if(false) {
        out = []

        for(let i = 0; i < this.sku.cupcake_slots; i ++) {
          out.push(this.flavours[i % this.flavours.length])
        }

        out = sortBy(out, o => o.name)

        console.log(out)

        return out
      } else {

        let chunks = chunk(out, Math.floor(out.length / this.flavours.length))
        return flatten(chunks.map((o,i) => {
          if(this.flavours[i]) {
            return fill(o, this.flavours[i])
          } else {
            return fill(o,"EXTRA")
          }
        }))
      }
    }
  }

}
</script>
