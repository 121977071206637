import { Controller } from "@hotwired/stimulus"
import Vue from 'vue/dist/vue.esm'
import Cupcakes from '../views/Cupcakes.vue'
import Cakes from '../views/Cakes.vue'

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

Vue.config.productionTip = false

Vue.filter('tightCurrency', function (value) {
  if (!value) return ''
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(value).replace('.00', '')
})

export default class extends Controller {
  static targets = ["app"]
  connect() {
    new Vue({
      components: {
        Cupcakes,
        Cakes
      }
    }).$mount(this.appTarget)
  }
}