<template>
  <section class="cupcake-size-choice">
    <div class="mx-2">
      <h2 class="mb-0 a-h">What size would you like?</h2>
      <template v-if="image">
      <a href="#" class="text-jblue text-sm mb-2 block a-h" @click.prevent="toggler = !toggler">Show me an example</a>
      <FsLightbox
  			:toggler="toggler"
  			:sources="[image]"
  		 /></template>
      <div class="buttons">
        <standard-button
          v-for="item in sizes" :key="item"
          @click="setSize(item)"
          :active="value == item"
        >{{item}}</standard-button>
      </div>
    </div>
  </section>
</template>

<script>
import StandardButton from '@components/StandardButton.vue'
import FsLightbox from "fslightbox-vue/v2";
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    image: {},
    sizes: {
      type: Array,
      default() {
        return ["mini","big"]
      }
    }
  },
  data() {
    return {
      toggler: false
    }
  },
  components: {
    StandardButton,
    FsLightbox
  },
  methods: {
    setSize(id) {
      this.$emit('input',id)
      this.$scrollTo(document.getElementById('box'), 1000, { offset: -50 })
    }
  }
}
</script>