<template>
  <section>
    <div class="mx-2">
      <h2>What size would you like?</h2>
      <template v-if="image">
        <a href="#" class="text-jblue text-sm mb-2 block a-h" @click.prevent="toggler = !toggler">Show me an example</a>
        <FsLightbox
    			:toggler="toggler"
    			:sources="[image]"
    		 /></template><template v-else><div class="mb-2"></div></template>
      
      <div class="buttons flex-wrap">
        <standard-button 
          v-for="item in skus" :key="item.id"
          @click="setSku(item)"
          :active="value == item"
        >
          <span class="size">{{item.cake_size}}"</span> <em class="lowercase"> for </em>
          <span class="price">{{item.price | tightCurrency }}</span>
          <template v-slot:hint v-if="item.cake_servings > 0">{{item.cake_servings}}+ small servings</template>
        </standard-button>
      </div>
    </div>
  </section>
</template>


<script>
import StandardButton from '@components/StandardButton.vue'
import { filter, map, findIndex, find } from 'lodash'
export default {
  model: {
    value: 'value',
    event: 'input'
  },
  props: {
    value: {},
    image: {},
    skus: {},
  },
  components: {
    StandardButton
  },

  methods: {
    setSku(id) {
      this.$emit('input',id)
      this.$scrollTo(document.getElementById('flavours'), 1000, { offset: -50 })
    }
  },
  computed: {
    filteredProducts() {
      return filter(this.$root.$data.products, {group: this.group})
    }
  }
}
</script>
