<template>
  <button 
    @click="$emit('click')"
    class="
      my-2
      sm:m-2
      block
      w-full
      sm:w-auto
      text-sm
      sm:whitespace-no-wrap
      border border-solid border-jpink
      px-4 py-2 
      text-jpink font-proxima 
      hover:bg-jpink
      hover:bg-opacity-25
      transition duration-500 ease-in-out
      focus:outline-none
      uppercase"
    :class="{
      'bg-jpink bg-opacity-25': active,
      'border-gray opacity-25': disabled
    }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
  .wrapper {
    max-width: 50%;
  }
  p {
    @apply text-sm;
    @apply mb-4;
  }
</style>